@use '@/styles/utils/mixins.scss' as *;

.callbackFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  margin-top: 7.5rem;

  @include mob() {
    margin-top: 5.125rem;
  }
  .formHeaderWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .formHeaderDescription {
      max-width: 25.75rem;
      width: 100%;
    }
  }

  .callbackFormContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    grid-column: span 2;
    width: 100%;
    @include tab() {
      grid-column: span 1;
    }
  }

  .callbackForm {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }

  .callbackFormField {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4.125rem;
    row-gap: 2.5rem;

    @include tab-des() {
      column-gap: 2.5rem;
    }

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .float-label {
        .as-label,
        .ant-select-selector,
        .float-input {
          background: $neutral01 !important;
        }
      }
    }
  }

  .acceptTerms {
    max-width: 22rem;
  }
  .contactFormImageContainer {
    max-width: 30rem;
    width: 100%;
    overflow: hidden;
    position: relative;

    @include tab-des() {
      max-width: 24rem;
    }

    img {
      border-radius: 1.25rem;
      object-fit: cover;
    }

    @include tab() {
      display: none;
    }
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .submitContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin: 0;
    padding: 0 3.75rem;
    align-content: center;
  }
  .homeButton {
    margin-top: 3.75rem;
    max-width: fit-content;
  }
  .formSubmit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 0.063rem solid $primaryAlt;
    border-radius: $imageRadius;
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($secondary, 1);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($secondary, 1);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($secondary, 1);
    margin-bottom: 3.75rem;

    .submitContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 25rem;
      padding: 3.75rem 2.5rem;
    }

    .submitLogo {
      margin-bottom: 5rem;

      @include tab() {
        margin-bottom: 3.75rem;
      }

      img {
        @include mob() {
          height: 6.25rem;
          width: 6.25rem;
        }
      }
    }

    .submitHeading {
      margin-bottom: 0.5rem;
    }

    .submitMessage {
      text-align: center;

      p {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
